import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { connect } from "react-redux"
import { showItemSizeOffcanvas } from '../../../features/Offcanvas/itemSizeOffcanvasSlice'
import { updateCart, updateQuantity, updatePrice } from '../../../features/state/checkoutItemsSlice'
import { cartDraftIndex, cartDraftData, cartOrderIndex, itemsDataUpdate } from '../../../features/Data/dataSlice'
import { showShopStatusModal } from '../../../features/Offcanvas/shopStatusModalSlice'
import { updateStockQuantity, updateStock, updateStockPrice } from '../../../features/Data/stockSlice'
import Veg from '../../images/icon/veg.png'
import NonVeg from '../../images/icon/non-veg.png'
import { showAppToast } from '../../../features/utilities/appToastSlice'

const ItemCard = ({
    showItemSizeOffcanvas,
    itemCardDetail,
    cardBg,
    checkoutItemList,
    updateCart,
    updateQuantity,
    updatePrice,
    price,
    pageName,
    shortcodeVisible,
    alternateTextVisible,
    colorVisible,
    draftData,
    draftIndex,
    cartDraftIndex,
    cartDraftData,
    cartOrderIndex,
    odIndex,
    showShopStatusModal,
    discountType,
    discountedAmount,
    totalDiscount,
    stockActionColor,
    updateStockQuantity, 
    updateStock,
    stockItemList,
    updateStockPrice,
    stockPrice,
    items,
    itemsDataUpdate,
    showAppToast
}) => {
    const [activeItem, setActiveItem] = useState([])
    const [stateName, setStateName] = useState('')
    const [tempTotalSalesQty, setTempTotalSalesQty] = useState(0)
    const page = window.location.pathname.split('/')
    useEffect(() => {
        setStateName(page[1])
    }, [page])
    let navigate = useNavigate()

    const handleItemDetailProfile = () => {
        navigate('/itemProfile')
    }

    useEffect(() => {
        if (pageName === 'newOrder') {
            updatePrice({
                subtotal : 0,
                cgst : 0,
                sgst : 0})
            updateCart([])   
        }
    }, [pageName])

    const calculateStock = (itemList, item) => {
        itemList.map((v,k) => {
            if (v.ID === item.ID) {
                v.SalesQty = parseInt(v.SalesQty) - 1 
            }
        })
        itemsDataUpdate({items: itemList})
    }
    const addToCheckoutItems = (item, pageName) => {
        let tempItemList = JSON.parse(JSON.stringify(items))
        if (pageName === 'NewOrder') {
            const currentShopStatus = localStorage.getItem('shopStatus')
            if (currentShopStatus === 'Close') {
                showShopStatusModal({shopStatusModalVisible: true, liveShopStatus: ''})
            } else {
                setActiveItem(item.ID)
                if (item.Sizes.length === 0) {
                        if (parseInt(item.SalesQty) === 0 && item.AllowNegativeStock === 'f') {
                        showAppToast({ toastMessage:'OUT OF STOCK', background: 'danger', timeout: '1000', icon:'close-circle-outline', position: 'toast-center', isVisible: true })
                    } else {
                        let tempItemObj = {
                            "quantity": 1,
                            "MRP":item.MRP,
                            "Cost":item.Cost,
                            "ItemTitle":item.ItemTitle,
                            "Item_ID":item.ID,
                            "Taxes":item.Taxes,
                            "Image": item.ImageURL,
                            "AlternateText": item.AlternateText,
                            "Status": false,
                            "FoodType": item.FoodType
                        }
                        let tempOrderObj = {
                            "DateTime": null,
                            "Items": [],
                            "OrderNumber": "",
                            "Subtotal": 0,
                            "Total":0,
                            "TotalQty": 0,
                            "TotalTax": {"cgst": 0, "sgst": 0},
                            "Type":"",
                            "OrderStatus": 0
                        }
                        let tempObj = JSON.parse(JSON.stringify(draftData))
                        let addNewItem = false
                        if (draftIndex !== null && draftIndex !== undefined && draftIndex !== '') {
                            if (tempObj[draftIndex].Status === 0) {
                                let addNewOrder = false
                                for (let i = 0; i < tempObj[draftIndex].Orders.length; i++) {
                                    if (tempObj[draftIndex].Orders[i].OrderStatus === 1) {
                                        addNewOrder = true
                                    }
                                }
                                if (addNewOrder) {
                                    tempObj[draftIndex].Orders.push(tempOrderObj)
                                    cartOrderIndex({odIndex: tempObj[draftIndex].Orders.length - 1})
                                    odIndex = tempObj[draftIndex].Orders.length - 1
                                }
                            }
                            if (tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].Items.length === 0) {
                                addNewItem = true
                                tempObj[draftIndex].TotalQuantity = 0
                            } else {
                                for (let i = 0; i < tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].Items.length; i++) {
                                    if (tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].Items[i].Item_ID === item.ID) {
                                        tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].Items[i].quantity = tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].Items[i].quantity + 1
                                        addNewItem = false
                                        break 
                                    } else {
                                        addNewItem = true                    
                                    }
                                }
                            }
                            if (addNewItem) {
                                tempObj[draftIndex].Orders[(odIndex) ? odIndex : 0].Items.push(tempItemObj)
                            }
                            tempObj[draftIndex].TotalQuantity = tempObj[draftIndex].TotalQuantity + 1
                            tempObj[draftIndex].Amount = tempObj[draftIndex].Amount + parseInt(item.MRP)
                            tempObj[draftIndex].BalanceAmount = tempObj[draftIndex].BalanceAmount + parseInt(item.MRP)
                            tempObj[draftIndex].Status = -1
                            tempObj[draftIndex].OrderType = (tempObj[draftIndex].OrderType === '') ? 'Counter Sale' : tempObj[draftIndex].OrderType
                            // tempObj[draftIndex].DiscountType = (discountType === 'percentage') ? totalDiscount + '%' : totalDiscount 
                            // tempObj[draftIndex].DiscountedAmount = discountedAmount
                            // tempObj[draftIndex].TotalDiscount = tempObj[draftIndex].Amount - discountedAmount
                            addNewItem = false
                        } else {
                            let draftIndexSet = false
                            for (let i = 0; i < draftData.length; i++) {
                                if (draftData[i].Status === -2) {
                                    draftIndexSet = true
                                    tempObj[i].Status = -1
                                    tempObj[i].OrderType = 'Counter Sale'
                                    tempObj[i].TotalQuantity = 1
                                    tempObj[i].Orders[0].Items.push(tempItemObj)
                                    cartDraftIndex({draftIndex: i})
                                    cartOrderIndex({odIndex: 0})
                                    tempObj[i].Amount = tempObj[i].Amount + parseInt(item.MRP)
                                    tempObj[i].BalanceAmount = tempObj[i].BalanceAmount + parseInt(item.MRP)
                                    break
                                }
                            }
                            if (!draftIndexSet) {
                                let defObj = {
                                    "OfflineInvoiceNumber": '',
                                    "Orders":[{
                                        "Items":[],
                                        "TotalQty":0,
                                        "Subtotal":0,
                                        "TotalTax":{
                                            "cgst": 0,
                                            "sgst":0
                                        },
                                        "Total":0,
                                        "Type":'',
                                        "DateTime":null,
                                        "OrderNumber":'',
                                        "OrderStatus": 0
                                    }],
                                    "Currency":"INR",
                                    "Phone":null,
                                    "Date": null,
                                    "UserName":"",
                                    "BalanceAmount":0,
                                    "PaymentMethod":"",
                                    "OrderType": '',
                                    "Amount": 0,
                                    "TransactionDate": null,
                                    "Customer_ID": "",
                                    "Status": -2
                                }
                                tempObj = JSON.parse(JSON.stringify(draftData))
                                tempObj.push(defObj)
                                tempObj[draftData.length].Status = -1
                                tempObj[draftData.length].OrderType = 'Counter Sale'
                                tempObj[draftData.length].TotalQuantity = 1
                                tempObj[draftData.length].Orders[0].Items.push(tempItemObj)
                                cartDraftIndex({draftIndex: draftData.length})
                                cartOrderIndex({odIndex: 0})
                                tempObj[draftData.length].Amount = tempObj[draftData.length].Amount + parseInt(item.MRP)
                                tempObj[draftData.length].BalanceAmount = tempObj[draftData.length].BalanceAmount + parseInt(item.MRP)
                                // tempObj[draftData.length].DiscountType = (discountType === 'percentage') ? totalDiscount + '%' : totalDiscount 
                                // tempObj[draftData.length].DiscountedAmount = discountedAmount
                                // tempObj[draftData.length].TotalDiscount = tempObj[draftData.length].Amount - discountedAmount
                            }
                        }
                        cartDraftData({draftData: tempObj})
                        if (pageName === 'NewOrder') {
                                let itemList = [...checkoutItemList]
                                // let checkoutItemIndex = itemList.findIndex(e=>e.item.ID === item.ID)
                                let checkoutItemIndex = -1
                                for (let i = 0; i < itemList.length; i++) {
                                    if (parseInt(itemList[i].item.ID) === parseInt(item.ID) && itemList[i].OrderIndex === odIndex) {
                                        checkoutItemIndex = i
                                        break
                                    }
                                }
                                if(checkoutItemIndex===-1){
                                    itemList.push({
                                        item : item, 
                                        quantity : 1,
                                        OrderStatus : 0,
                                        OrderIndex: (odIndex === null ? 0 : odIndex)
                                    })
                                    updateCart(itemList)
                                } else {
                                    updateQuantity({checkoutItemIndex:checkoutItemIndex,quantity:Number(itemList[checkoutItemIndex]?.quantity)+1})
                                    itemList.splice(checkoutItemIndex,1,{item : item, quantity : itemList[checkoutItemIndex].quantity+1, OrderStatus: 0, OrderIndex: (odIndex === null ? 0 : odIndex)})
                                    updateCart(itemList)
                                }
                                updatePrice({
                                    subtotal : Number(price.subtotal)+Number(item.MRP),
                                    cgst : 0,
                                    sgst : 0})
                        }
                        calculateStock(tempItemList, item)

                    }
                } else {
                    showItemSizeOffcanvas({itemSizeOffcanvasVisible: true, itemValue: item})
                }
            }
        } else if (pageName === 'ManageStock') {
            if (item.Sizes.length === 0) {
                setActiveItem(item.ID)
                let itemList = [...stockItemList]
                let stockItemIndex = -1
                for (let i = 0; i < itemList.length; i++) {
                    if (parseInt(itemList[i].item.ID) === parseInt(item.ID)) {
                        stockItemIndex = i
                        break
                    }
                }
                if(stockItemIndex===-1){
                    itemList.push({
                        item : item, 
                        quantity : 1,
                        OrderStatus : 0,
                        OrderIndex: (odIndex === null ? 0 : odIndex)
                    })
                    updateStock(itemList)
                } else {
                    updateStockQuantity({stockItemIndex:stockItemIndex,quantity:Number(itemList[stockItemIndex]?.quantity)+1})
                    itemList.splice(stockItemIndex,1,{item : item, quantity : itemList[stockItemIndex].quantity+1, OrderStatus: 0, OrderIndex: (odIndex === null ? 0 : odIndex)})
                    updateStock(itemList)
                }
                updateStockPrice({
                    subtotal : Number(stockPrice.subtotal)+Number(item.MRP),
                    cgst : 0,
                    sgst : 0})
            } else {
                showItemSizeOffcanvas({itemSizeOffcanvasVisible: true, itemValue: item})
            }
        }
    }

    let activeItemID = []
    useEffect(() => {
        if (stateName === 'newOrder') {
            if (checkoutItemList.length > 0) {
                checkoutItemList.map((v,k) => {
                    activeItemID.push(v.item.ID)
                    setActiveItem(activeItemID)
                })   
            } else {
                setActiveItem([])
            }
        } else if (stateName === 'manageStock') {
            if (stockItemList.length > 0) {
                stockItemList.map((v,k) => {
                    activeItemID.push(v.item.ID)
                    setActiveItem(activeItemID)
                })   
            } else {
                setActiveItem([])
            }
        }
    }, [checkoutItemList, stockItemList, stateName])
    useEffect(() => {
        if (itemCardDetail.Sizes.length > 0) {
            let totalSalesQty = 0
            itemCardDetail.Sizes.map((v,k) => {
                totalSalesQty = parseInt(totalSalesQty) + parseInt(v.SalesQty)
            })
            setTempTotalSalesQty(totalSalesQty)
        }
    }, [itemCardDetail])
    return (
        <div class="card product-card shadow-none" onClick={(pageName === 'NewOrder' || pageName === 'OrderDetail' || pageName === 'ManageStock' || pageName === 'BusinessSetup') ? ()=>addToCheckoutItems(itemCardDetail, pageName) : handleItemDetailProfile} style={{backgroundColor: (itemCardDetail?.Color && colorVisible) ? itemCardDetail?.Color : 'transparent', overflow: 'hidden', border: 'solid 2px', borderColor: (activeItem.includes(itemCardDetail.ID)) ? ((stateName === 'manageStock') ? stockActionColor :'#24265d') : 'gainsboro', height: '100%'}}>
            {stateName === 'newOrder' &&
                <>
                    {itemCardDetail.Sizes.length === 0 &&
                        <>
                            {itemCardDetail.Stockable === 't' &&
                                <>
                                    {((itemCardDetail.SalesQty === 0 || itemCardDetail.SalesQty === '0') && itemCardDetail.AllowNegativeStock === 'f') &&
                                        <div class="d-flex align-items-center justify-content-center" style={{position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, backgroundColor: 'rgba(255,255,255,0.5)'}}>
                                            <span class="badge bg-danger">OUT OF STOCK</span>
                                        </div>
                                    }
                                </>
                            }
                        </>
                    }
                    {itemCardDetail.Sizes.length > 0 &&
                        <>
                        {itemCardDetail.Stockable === 't' &&
                            <>
                                {tempTotalSalesQty === 0 &&
                                    <div class="d-flex align-items-center justify-content-center" style={{position: 'absolute', top: 0, right: 0, left: 0, bottom: 0, backgroundColor: 'rgba(255,255,255,0.5)'}}>
                                        <span class="badge bg-danger">OUT OF STOCK</span>
                                    </div>
                                }
                            </>
                        }
                        </>
                    }
                </>
            }
            <div class="card-body">
                <div>
                    {(itemCardDetail !== null || itemCardDetail !== undefined) && (itemCardDetail.ImageURL === null || itemCardDetail.ImageURL === undefined || itemCardDetail.ImageURL === '') &&
                        <img src="https://us.123rf.com/450wm/viktorijareut/viktorijareut1503/viktorijareut150300119/37649514-grey-tray-kitchenware-trey-isolated-tray-vector-closed-tray.jpg?ver=6" class="image" alt="product image" style={{objectFit:'cover'}} />
                    }
                    {itemCardDetail && itemCardDetail.ImageURL &&
                        <img src={itemCardDetail.ImageURL && itemCardDetail.ImageURL} class="image" alt="product image" style={{objectFit:'cover'}} />
                    }
                    {itemCardDetail && itemCardDetail.FoodType === 'veg' &&
                        <img src={Veg} width="18px" style={{position: 'absolute', top: 13, right: 13, backgroundColor: 'white'}} />
                    }
                    {itemCardDetail && itemCardDetail.FoodType === 'non-veg' &&
                        <img src={NonVeg} width="18px" style={{position: 'absolute', top: 13, right: 13, backgroundColor: 'white'}} />
                    }
                </div>
                <h2 class="title d-flex justify-content-between" style={{fontSize: '13px'}}>
                    <a href="javascript:void(0)">
                        <div class="fw-bolder" style={{fontSize: '13px'}}>
                            {(alternateTextVisible === 'shortcode') &&
                                <>
                                    {itemCardDetail?.Shortcode}
                                </>
                            }
                            {(alternateTextVisible === 'englishText') &&
                                <>
                                    {itemCardDetail.ItemTitle && itemCardDetail.ItemTitle}
                                </>
                            }
                            {(alternateTextVisible === 'alternateText') &&
                                <>
                                    {itemCardDetail?.AlternateText} 
                                </>
                            }
                            {itemCardDetail.Sizes.length > 0 &&
                                <>
                                    <br />
                                    {(itemCardDetail && itemCardDetail.Sizes).map((v,k) => {
                                        return <>
                                            {v.SalesQty > 0 &&
                                                <span class="badge bg-primary fs-10" style={{height: '14px', marginRight: '2px'}}>{v.SizeCategory} {v.SalesQty}</span>
                                            }
                                        </>
                                    })}
                                    <br />
                                </>
                            }
                            {(itemCardDetail?.Sizes?.length > 0) &&
                                <small>{itemCardDetail && itemCardDetail.Sizes.length} Size(s)</small>
                            }
                            <small>{itemCardDetail.Subtitle && itemCardDetail.Subtitle}</small>
                        </div>
                        {stateName === 'newOrder' &&
                            <>
                                {(itemCardDetail.Sizes.length === 0) &&
                                    <>
                                        {itemCardDetail.Stockable === 't' &&
                                            <>
                                                {itemCardDetail.SalesQty &&
                                                    <div class="badge w-auto bg-primary position-absolute" style={{fontSize: '8px', height: '14px', bottom: '12px', right: '12px'}}><ion-icon name="cart-outline" style={{paddingBottom: '2px', paddingRight: '2px'}}></ion-icon> {itemCardDetail.SalesQty}</div>
                                                }
                                            </>
                                        }
                                    </>
                                }
                            </>
                        }
                        {stateName === 'manageStock' &&
                            <>
                                {(itemCardDetail.Sizes.length === 0) &&
                                    <>
                                        <div class="badge w-auto bg-primary position-absolute" style={{fontSize: '8px', height: '14px', bottom: '12px', right: '12px'}}><ion-icon name="cart-outline" style={{paddingBottom: '2px', paddingRight: '2px'}}></ion-icon> {itemCardDetail.SalesQty}</div>
                                    </>
                                }
                            </>
                        }
                        <div class="badge position-absolute w-auto rounded-0" style={{fontSize: '8px',top: '20px', right: '8px', backgroundColor: itemCardDetail.labelColor && itemCardDetail.labelColor}}>{itemCardDetail.label && itemCardDetail.label}</div>
                    </a>
                </h2>
                {itemCardDetail.Sizes.length === 0 &&
                    <div class="row">
                        <div class="col-12 col-md-12">
                            <p class="price mb-0 text-secondary" style={{fontSize: '13px'}}>₹{itemCardDetail.MRP && itemCardDetail.MRP}</p>
                        </div>
                        {/* {itemCardDetail.GroupItemID &&
                            <div class="col-6 col-md-6 d-flex justify-content-md-end">
                                <button class="px-1 fs-10 btn btn-text btn-sm d-flex align-items-center ps-1 pe-1" style={{height: '18px'}} onClick={(pageName === 'NewOrder' || pageName === 'OrderDetail') ? (e) => {handleItemSize(e, (itemCardDetail.GroupItemID && itemCardDetail.GroupItemID), itemCardDetail.GroupItemID && itemCardDetail.GroupItemID)} : handleItemDetailProfile}> <ion-icon name="color-filter-outline" class="text-secondary"></ion-icon></button>
                            </div>
                        } */}
                    </div>
                }
            </div>
        </div>
        
    )
}

const mapStateToProps = ({checkoutItems, general, data, stock}) => {
    const {
        checkoutItemList,
        price
    } = checkoutItems
    const {
        shortcodeVisible,
        alternateTextVisible,
        colorVisible
    } = general
    const {
        draftData,
        draftIndex,
        odIndex,
        discountType,
        discountedAmount,
        totalDiscount,
        items
    } = data
    const {
        stockActionColor,
        stockItemList,
        stockPrice
    } = stock
    return {
        checkoutItemList,
        price,
        shortcodeVisible,
        alternateTextVisible,
        colorVisible,
        draftData,
        draftIndex,
        odIndex,
        discountType,
        discountedAmount,
        totalDiscount,
        stockActionColor,
        stockItemList,
        stockPrice,
        items
    }
  }
  
const mapDispatchToProps = {
    showItemSizeOffcanvas,
    updateCart,
    updateQuantity,
    updatePrice,
    cartDraftIndex,
    cartDraftData,
    cartOrderIndex,
    showShopStatusModal,
    updateStockQuantity, 
    updateStock,
    updateStockPrice,
    itemsDataUpdate,
    showAppToast
}

export default connect(mapStateToProps, mapDispatchToProps)(ItemCard)
